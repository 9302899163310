import React, { Component } from "react";
import "../styles/typography.css";
import "../styles/main.css";
import HeaderSection, { headerHeight } from "../components/headerSection";
import thumbnailPlaceholder from "../assets/images/thumbnailPlaceholder.png";

const pp = (title, item) => {
  console.log(`${title}: ${JSON.stringify(item, null, 2)}`);
};

class PagePiece extends Component {
  // videoID = "";
  // videoSrc = "";
  // piece = {};
  constructor(props) {
    super(props);
    // this.piece = ;
    // pp("props.piece", props.pageContext.piece);
    // this.videoID = `artbymann${props.pageContext.piece.title}`;
    // this.videoSrc = `../assets/mp4s/${props.pageContext.piece.mp4Filename}`;
    this.state = {
      windowWidth: 0,
      windowHeight: 0,
      paused: false,
      piece: props.pageContext.piece,
      videoID: `artbymann${props.pageContext.piece.title}`,
      thumbnailID: `artbymann${props.pageContext.piece.title}_thumbnail`,
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    import(`../assets/thumbnails/${this.state.piece.thumbnailFilename}`).then(
      (thumbnail) => {
        document.getElementById(this.state.thumbnailID).src = thumbnail.default;
      }
    );
    import(`../assets/mp4s/${this.state.piece.mp4Filename}`).then((video) => {
      document.getElementById(this.state.videoID).src = video.default;
      document.getElementById(this.state.videoID).hidden = false;
      document.getElementById(this.state.thumbnailID).hidden = true;
      var vid = document.getElementById(this.state.videoID);
      vid.play();
      this.setState({ isPaused: false });
    });
  }

  updateDimensions() {
    const windowWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const windowHeight = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    console.log(windowWidth);
    this.setState({ windowWidth, windowHeight });
  }

  render() {
    const { windowHeight, windowWidth } = this.state;

    const universalPadding = 0.05 * windowWidth;

    const minLargeWidth = 800;
    const isSmallMode = windowWidth < minLargeWidth;

    // const videoID = `artbymann${props.piece.title}`;

    const togglePausePlay = () => {
      if (document.getElementById(this.state.videoID).hidden) {
        document.getElementById(this.state.videoID).hidden = false;
        document.getElementById(this.state.thumbnailID).hidden = true;
      }
      this.setState({ isPaused: !this.state.isPaused });
      var vid = document.getElementById(this.state.videoID);
      if (this.state.isPaused) {
        vid.pause();
      } else {
        vid.play();
      }
    };

    const sidebarWidth = 275;

    return (
      <div
        style={{
          paddingLeft: universalPadding,
          paddingRight: universalPadding,
        }}
      >
        <HeaderSection></HeaderSection>
        <div style={{ display: "inline-block", marginRight: 45 }}>
          <video
            hidden
            onClick={togglePausePlay}
            id={this.state.videoID}
            loop
            muted
            style={{
              width: 1000,
              height: 1000,
              boxShadow: "0px 4px 10px 2px rgba(0, 0, 0, 0.25)",
              cursor: "pointer",
            }}
          ></video>
          <img
            onClick={togglePausePlay}
            id={this.state.thumbnailID}
            src={thumbnailPlaceholder}
            style={{
              width: 1000,
              height: 1000,
              boxShadow: "0px 4px 10px 2px rgba(0, 0, 0, 0.25)",
              cursor: "pointer",
            }}
          ></img>
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            height: "100%",
          }}
        >
          <div style={{ fontFamily: "DIN", fontSize: 56 }}>
            {this.state.piece.title}
          </div>
          <div style={{ fontFamily: "SFMono", marginTop: 37, fontSize: 27 }}>
            Collection
          </div>
          <div style={{ fontFamily: "DIN", fontSize: 34 }}>01_LIQUIDS</div>
          <div style={{ fontFamily: "DIN", marginTop: 37, fontSize: 24 }}>
            Luke Mann, 2021
          </div>
        </div>
        <div style={{ height: 0.5 * windowHeight }}></div>
      </div>
    );
  }
}

export default PagePiece;
